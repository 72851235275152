import { useState } from 'react'

import * as Yup from 'yup'

import api from '~/services/api'
import Message from '~/utils/messages'

function useStore() {
  const [loading, setLoading] = useState(false)
  const [storeProductsCategories, setStoreProductsCategories] = useState([])
  const [storeProducts, setStoreProducts] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [totalCountProducts, setTotalCountProducts] = useState(0)
  const [countBuyer, setCountBuyer] = useState(0)
  const [countProductsByCategories, setCountProductsByCategories] = useState(0)

  const productsSchema = {
    name: Yup.string().required('O nome deve ser informado'),
    description: Yup.string().required('A descrição deve ser informada'),
    value: Yup.number().required('O valor deve ser informado'),
    currencyType: Yup.string().required('O tipo de moeda deve ser informado'),
    storeProductsCategoriesId: Yup.string()
      .uuid()
      .required('O ID da categoria deve ser informado'),
    stockLimit: Yup.number().nullable(),
    status: Yup.boolean().required('Status precisa ser informado'),
    startedAt: Yup.date().required('Data de início precisa ser informada'),
    expiredAt: Yup.date().required('Data de expiração precisa ser informada'),
  }

  const paginationSchema = {
    limit: Yup.number().required('O limite precisa ser informado'),
    offset: Yup.number().required(
      'A posição inicial do retorno precisa ser informada'
    ),
  }

  async function getStoreProductsCategories(params) {
    try {
      setLoading(true)

      const schema = Yup.object().shape(paginationSchema)

      const isValid = schema.isValidSync(params)

      if (!isValid) {
        const validate = schema.validateSync(params)
        throw new Error({ message: validate })
      }

      const res = await api.get('/store-products-categories', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setTotalCount(Number(res.data[0]?.total_rows || 0))
      return setStoreProductsCategories(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function createStoreProductsCategories(params, pagination) {
    try {
      setLoading(true)

      const schema = Yup.object().shape({
        title: Yup.string().required('O nome precisa ser informado'),
        description: Yup.string().required('A descrição precisa ser informada'),
      })

      const isValid = schema.isValidSync(params)

      if (!isValid) {
        const validate = schema.validateSync(params)
        throw new Error({ message: validate })
      }

      const res = await api.post('/store-products-categories', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getStoreProductsCategories(pagination)
      return Message().success('Categoria criada com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function updateStoreProductsCategories(params, pagination) {
    try {
      setLoading(true)

      const schema = Yup.object().shape({
        storeProductsCategoriesId: Yup.string()
          .uuid()
          .required('O ID da categoria precisa ser informado'),
        title: Yup.string().required('O nome precisa ser informado'),
        description: Yup.string().required('A descrição precisa ser informada'),
      })

      const isValid = schema.isValidSync(params)

      if (!isValid) {
        const validate = schema.validateSync(params)
        throw new Error({ message: validate })
      }

      const res = await api.put('/store-products-categories', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getStoreProductsCategories(pagination)
      return Message().success('Categoria alterada com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function getStoreProducts(params) {
    try {
      setLoading(true)

      const schema = Yup.object().shape(paginationSchema)

      const isValid = schema.isValidSync(params)

      if (!isValid) {
        const validate = schema.validateSync(params)
        throw new Error({ message: validate })
      }

      const res = await api.get('/store-products', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      setTotalCountProducts(Number(res.data[0]?.total_rows || 0))
      return setStoreProducts(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function createStoreProducts(params, pagination) {
    try {
      setLoading(true)

      const schema = Yup.object().shape({
        ...productsSchema,
        file: Yup.mixed().required('Imagem precisa ser enviada'),
      })

      const isValid = schema.isValidSync(params)

      if (!isValid) {
        const validate = schema.validateSync(params)
        throw new Error({ message: validate })
      }

      const data = new FormData()

      data.set('status', Boolean(params.status))
      data.set('storeProductsCategoriesId', params.storeProductsCategoriesId)
      data.set('currencyType', params.currencyType)
      data.set('value', params.value)
      data.set('description', params.description)
      data.set('name', params.name)
      data.set('expiredAt', params.expiredAt)
      data.set('startedAt', params.startedAt)
      data.set('has_delivery', params.hasDelivery)
      data.append('file', params.file)

      if (params.stockLimit) {
        data.set('stockLimit', params.stockLimit)
      }

      const res = await api.post('/store-products', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getStoreProducts(pagination)
      return Message().success('Produto cadastrado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function updateStoreProducts(params, pagination) {
    try {
      setLoading(true)

      const schema = Yup.object().shape({
        ...productsSchema,
        file: Yup.mixed().notRequired(),
      })

      const isValid = schema.isValidSync(params)

      if (!isValid) {
        const validate = schema.validateSync(params)
        throw new Error({ message: validate })
      }

      const data = new FormData()

      data.set('status', Boolean(params.status))
      data.set('storeProductsCategoriesId', params.storeProductsCategoriesId)
      data.set('currencyType', params.currencyType)
      data.set('value', params.value)
      data.set('description', params.description)
      data.set('name', params.name)
      data.set('expiredAt', params.expiredAt)
      data.set('startedAt', params.startedAt)
      data.set('storeProductsId', params.uid)
      data.set('has_delivery', params.hasDelivery)

      if (params.file) {
        data.append('file', params.file)
      }

      if (params.stockLimit) {
        data.set('stockLimit', params.stockLimit)
      }

      const res = await api.put('/store-products', data, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      getStoreProducts(pagination)
      return Message().success('Produto alterado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function getBuyers(productId) {
    try {
      setLoading(true)

      const schema = Yup.object().shape({
        productId: Yup.string()
          .uuid()
          .required('O ID do produto deve ser informado'),
      })

      const isValid = schema.isValidSync({ productId })

      if (!isValid) {
        const validate = schema.validateSync({ productId })
        throw new Error({ message: validate })
      }

      const res = await api.get('/users-store-products-bag/buyers', {
        params: { storeProductsId: productId },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setCountBuyer(Number(res.data[0].count))
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function getProductsByCategoriesId(categoriesId) {
    try {
      setLoading(true)

      const schema = Yup.object().shape({
        categoriesId: Yup.string()
          .uuid()
          .required('O ID da categoria deve ser informado'),
      })

      const isValid = schema.isValidSync({ categoriesId })

      if (!isValid) {
        const validate = schema.validateSync({ categoriesId })
        throw new Error({ message: validate })
      }

      const res = await api.get('/store-products/categories', {
        params: { categoriesId },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setCountProductsByCategories(Number(res.data[0].count))
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    getStoreProductsCategories,
    loading,
    storeProductsCategories,
    totalCount,
    createStoreProductsCategories,
    updateStoreProductsCategories,
    getStoreProducts,
    storeProducts,
    createStoreProducts,
    totalCountProducts,
    updateStoreProducts,
    getBuyers,
    countBuyer,
    getProductsByCategoriesId,
    countProductsByCategories,
  }
}

export default useStore
