import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useSharedLink() {
  const [extraData, setExtraData] = useState([])

  async function getSharedLinkExtraDataByMissionUserId(missionUserId) {
    try {
      const response = await api.get(`/shared-links-missions-users/${missionUserId}`);
      setExtraData(response.data);
    } catch (error) {
      return Message().error(error.message)
    }
  }

  return {
    getSharedLinkExtraDataByMissionUserId,
    extraData
  }
}
