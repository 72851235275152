import { useState } from 'react'

import api from '~/services/api'
import Message from '~/utils/messages'

export default function useMissionsUsers() {
  const [loadingRemoveAll, setLoadingRemoveAll] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState(false)
  const [loadingResults, setLoadingResults] = useState(false)
  const [missionsUsersData, setMissionsUsersData] = useState({})
  const [currentParticipants, setCurrentParticipants] = useState([])
  const [results, setResults] = useState(null)

  async function removeAllUsers(missionsUsersIds) {
    try {
      setLoadingRemoveAll(true)

      const res = await api.put(
        'missions-users/remove-all',
        { missionsUsersIds },
        {
          validateStatus() {
            return true
          },
        }
      )

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Usuários removidos com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingRemoveAll(false)
    }
  }

  async function getCurrentParticipants(params) {
    try {
      const res = await api.get('missions-users/participants', {
        params: { ...params },
        validateStatus() {
          return true
        },
      })

      return setCurrentParticipants(res.data)
    } catch (error) {
      return Message().error(error.response.data.message)
    }
  }

  async function getProgress(params) {
    try {
      setLoadingProgress(true)

      const res = await api.get('missions-users/admin/progress', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setMissionsUsersData(res.data.missionsUsersData[0])
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingProgress(false)
    }
  }

  async function revertExpired(params) {
    try {
      setLoadingProgress(true)

      const res = await api.put('missions-users/admin/revert-expired', params, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return Message().success('Item alterado com sucesso')
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingProgress(false)
    }
  }

  async function getResults(params) {
    try {
      setLoadingResults(true)

      const res = await api.get('missions-users/admin/results', {
        params,
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      return setResults(res.data)
    } catch (error) {
      return Message().error(error.message)
    } finally {
      setLoadingResults(false)
    }
  }

  return {
    removeAllUsers,
    loadingRemoveAll,
    getCurrentParticipants,
    currentParticipants,
    getProgress,
    loadingProgress,
    missionsUsersData,
    revertExpired,
    getResults,
    loadingResults,
    results,
  }
}
