import React, { useEffect, useState, useCallback } from 'react'

import dayjs from 'dayjs'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import _ from 'lodash'
import { Confirm } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import useForms from '~/hooks/useForms'
import useGeo from '~/hooks/useGeo'
import useMissions from '~/hooks/useMissions'
import useMissionsUsers from '~/hooks/useMissionsUsers'
import usePaginationTable from '~/hooks/usePaginationTable'
import useQueryFilters from '~/hooks/useQueryFilters'
import useSubCategories from '~/hooks/useSubCategories'
import {
  Item,
  Confirm as ConfirmModal,
  Filter,
} from '~/pages/Missions/Launched/components'
import api from '~/services/api'
import { MISSIONS } from '~/services/api/endpoints'
import message from '~/utils/messages'

import CurrentParticipants from '../Open/components/CurrentParticipants'
import ListEditionMissionBatch from './components/ListEditionMissionBatch'
import { Replace, Attachments, Detail, Forms } from './components/Modals'
import ModalEditAllMissions from './components/Modals/Detail/modalEditAllMissions'

const columns = [
  'COD',
  'Identificador',
  'Cliente',
  'Categoria',
  'Nome',
  'Criador',
  'Criado Em',
  'Ativado Em',
  'Reativações',
  'Expira Em',
  'Status',
  '',
]

const columnsMapForApiSort = {
  Cliente: 'c.name',
  Categoria: 'mm.title',
  Nome: 'm.name',
  'Criado Em': 'm.created_at',
  'Expira Em': 'm.expired_at',
}

const initialFilters = {
  limit: 15,
  offset: 0,
  order: 'm.created_at',
  direction: 'desc',
  final: null,
  initial: null,
  search: null,
  ufs: null,
  city: null,
  customerId: null,
  categoryId: null,
  missionsMainId: null,
  iniCreate: null,
  finCreate: null,
  iniExpired: null,
  finExpired: null,
  missionProgress: null,
}

export default function Missions() {
  const [missions, setMissions] = useState([])
  const [isLoadingMissions, setIsLoadingMissions] = useState(true)

  const [showConfirm, setShowConfirm] = useState(false)
  const [showConfirmEditAllMissions, setShowConfirmEditAllMissions] =
    useState(false)

  const [isOpenModalForm, setIsOpenModalForm] = useState(false)
  const [isOpenModalAttachments, setIsOpenModalAttachments] = useState(false)
  const [isOpenModalParticipants, setIsOpenModalParticipants] = useState(false)
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [selectedForm, setSelectedForm] = useState('')

  const [selectedMission, setSelectedMission] = useState({ status: false })
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [errorsMissionDetail, setErrorsMissionDetail] = useState([])
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [openReplaceModal, setOpenReplaceModal] = useState(false)
  const [openListEditionMissionBatch, setOpenListEditionMissionBatch] =
    useState(false)

  const {
    customFields,
    setCustomFields,
    getSubCategoryById,
    onChangeInputValueCustomField,
  } = useSubCategories()
  const {
    onSearchAddress,
    addresses,
    setAddresses,
    loadingAddresses,
    onGeocodeFormat,
  } = useGeo()
  const { getCurrentParticipants, currentParticipants } = useMissionsUsers()
  const {
    customFieldsAnswersOfMissionSelected,
    setCustomFieldsAnswersOfMissionSelected,
    updateAllMissions,
    loadingMissions,
    onUpdateMission,
  } = useMissions()

  const { getForms, forms } = useForms()

  const parseQueryParamsBeforeSetFilters = useCallback(queryObj => {
    const parsedParams = { ...queryObj }

    if (parsedParams.limit) {
      parsedParams.limit = parseInt(parsedParams.limit, 0)
    }
    if (parsedParams.offset) {
      parsedParams.offset = parseInt(parsedParams.offset, 0)
    }
    if (parsedParams.iniCreate) {
      parsedParams.iniCreate = new Date(parseInt(parsedParams.iniCreate, 0))
    }
    if (parsedParams.finCreate) {
      parsedParams.finCreate = new Date(parseInt(parsedParams.finCreate, 0))
    }
    if (parsedParams.iniExpired) {
      parsedParams.iniExpired = new Date(parseInt(parsedParams.iniExpired, 0))
    }
    if (parsedParams.finExpired) {
      parsedParams.finExpired = new Date(parseInt(parsedParams.finExpired, 0))
    }

    return parsedParams
  }, [])

  const parseFiltersBeforeSetQueryParams = useCallback(filters => {
    const parsedFilters = {}

    if (filters.cities) {
      parsedFilters.cities = filters.cities.map(cityObj => cityObj.cityName)
    }

    return parsedFilters
  }, [])

  const {
    filters,
    debouncedFilters,
    setFilters,
    onChangeFilters,
    clearFilters,
  } = useQueryFilters(
    initialFilters,
    parseQueryParamsBeforeSetFilters,
    parseFiltersBeforeSetQueryParams
  )
  const {
    count,
    emptyText,
    activePage,
    totalCount,
    handleSort,
    onPageChange,
    calculatePagination,
  } = usePaginationTable(debouncedFilters, setFilters, columnsMapForApiSort)

  const getValidParams = paramsObj => {
    const parsedParams = { ...paramsObj }

    if (parsedParams.iniCreate) {
      parsedParams.iniCreate = new Date(parsedParams.iniCreate).getTime()
    }
    if (parsedParams.finCreate) {
      parsedParams.finCreate = new Date(parsedParams.finCreate).getTime()
    }
    if (parsedParams.iniExpired) {
      parsedParams.iniExpired = new Date(parsedParams.iniExpired).getTime()
    }
    if (parsedParams.finExpired) {
      parsedParams.finExpired = new Date(parsedParams.finExpired).getTime()
    }

    return parsedParams
  }

  const getMissions = useCallback(async () => {
    try {
      setIsLoadingMissions(true)
      const validParams = getValidParams(debouncedFilters)

      if (_.isArray(validParams.ufs)) {
        validParams.uf = validParams.ufs.join(',')
        delete validParams.ufs
      }
      if (_.isArray(validParams.cities)) {
        const cities = validParams.cities.map(cityObj => cityObj.cityName)
        validParams.city = cities.join(',')
        delete validParams.cities
      }

      const res = await api.get(MISSIONS, { params: validParams })
      setMissions(res.data.data)
      calculatePagination(res.data.count)
    } catch (error) {
      message().error('Houve um erro no servidor')
    } finally {
      setIsLoadingMissions(false)
    }
  }, [debouncedFilters, calculatePagination])

  const onEditorStateChange = editorStateParam => {
    setEditorState(editorStateParam)
    setSelectedMission(sm => ({
      ...sm,
      description: draftToHtml(
        convertToRaw(editorStateParam.getCurrentContent())
      ),
    }))
  }

  async function submitMissionDetail() {
    const customFieldsAnswers = customFields
      .filter(item => {
        if (!item.value && item.value.length === 0) {
          return false
        }

        return true
      })
      .map(item => ({
        id: item.id,
        value: item.value,
      }))

    const index = missions.findIndex(i => i.uid === selectedMission.uid)

    setShowConfirm(false)
    setErrorsMissionDetail([])

    setIsOpenModalDetail(false)

    selectedMission.expired_at = dayjs(selectedMission.expired_at).format(
      'YYYY-MM-DD 22:00'
    )
    selectedMission.activated_at = dayjs(selectedMission.activated_at).format(
      'YYYY-MM-DD 05:00'
    )

    missions[index] = { ...missions[index], ...selectedMission }

    setMissions(missions)
    setCustomFields([])
    setCustomFieldsAnswersOfMissionSelected([])
    const missionUpdated = await onUpdateMission({
      ...missions[index],
      ...selectedMission,
      custom_fields: customFieldsAnswers,
    })

    missions[index] = {
      ...missions[index],
      ...selectedMission,
      ...missionUpdated,
    }

    setMissions([...missions])
  }

  function onRemove() {
    setShowConfirm(false)
    setSelectedMission(prevState => ({ ...prevState, deleted: true }))

    return onUpdateMission({ ...selectedMission, deleted: true })
  }

  function handlerDate(key, value) {
    return setSelectedMission(prev => ({ ...prev, [key]: value }))
  }

  async function changeAddress(address) {
    const result = await onGeocodeFormat(address.address_components)

    setSelectedMission(sm => ({
      ...sm,
      ...result,
      uf: result.state,
      ...address.geometry.location,
      place: address.formatted_address,
    }))

    return setAddresses([])
  }

  const handlerInput = ({ target }) => {
    if (target.id === 'searchAddress') {
      return setTimeout(() => {
        if (target.value.length > 2) {
          return onSearchAddress(target.value)
        }
      }, 1000)
    }

    return setSelectedMission(sm => ({ ...sm, [target.id]: target.value }))
  }

  function onChangeStatus() {
    return setSelectedMission(prevState => ({
      ...prevState,
      status: !selectedMission?.status,
    }))
  }

  function showConfirmModal(item) {
    setSelectedMission(prevState => ({ ...prevState, ...item }))

    return setShowConfirm(true)
  }

  function onChangeCategory(e, { value }) {
    return setSelectedMission(sm => ({ ...sm, missions_main_id: value }))
  }

  const updateMissionRowStatus = item => {
    if (
      item.participants === parseInt(item.has_finished, 2) &&
      item.participants > 0
    ) {
      return message().error(
        'Você não pode mais alterar o status, esta missão já foi finalizada!'
      )
    }

    if (item.has_attachment && parseInt(item.attachment_count, 2) === 0) {
      return message().error('Você precisa importar um arquivo para missão!')
    }

    if (item.has_forms && parseInt(item.forms_count, 2) === 0) {
      return message().error('Você precisa escolher um formulário!')
    }

    const updatedMission = { ...item, status: !item.status }
    const updatedMissionIdx = missions.findIndex(
      item => item.uid === updatedMission.uid
    )

    const newMissionsList = [...missions]
    newMissionsList[updatedMissionIdx] = updatedMission
    setMissions(newMissionsList)

    return onUpdateMission(updatedMission)
  }

  function handlerForms(e, { value }) {
    setSelectedForm(value)
  }

  async function handleEditModal() {
    if (filters.customerId) {
      await getForms(filters.customerId)
    }

    return setIsOpenEditModal(true)
  }

  async function submitMissionEdit(isActiveStatus) {
    if (!isActiveStatus) {
      delete selectedMission.status
    }

    delete selectedMission.state

    setShowConfirmEditAllMissions(false)

    const parsedFilters = getValidParams(filters)

    if (selectedForm) {
      await updateAllMissions({
        filters: parsedFilters,
        totalCount,
        values: selectedMission,
        formId: selectedForm,
      })
    } else {
      await updateAllMissions({
        filters: parsedFilters,
        totalCount,
        values: selectedMission,
      })
    }

    setSelectedForm('')
    setIsOpenEditModal(false)

    return getMissions()
  }

  function openModalParticipants(item) {
    setIsOpenModalParticipants(true)

    return getCurrentParticipants({
      missionId: item.uid,
      progress: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    })
  }

  function openModalForms(item) {
    setIsOpenModalForm(true)
    return setSelectedMission(prev => ({ ...prev, ...item }))
  }

  function openModalAttachment(item) {
    setIsOpenModalAttachments(true)

    return setSelectedMission(prevState => ({ ...prevState, ...item }))
  }

  function openModalDetail(item) {
    if (item.custom_fields) {
      setCustomFieldsAnswersOfMissionSelected(item.custom_fields.fields)
    }
    const contentBlock = htmlToDraft(item.description)
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    )

    setIsOpenModalDetail(true)
    setEditorState(EditorState.createWithContent(contentState))

    return setSelectedMission(prevState => ({ ...prevState, ...item }))
  }

  function closeModalDetail() {
    setCustomFields([])
    setCustomFieldsAnswersOfMissionSelected([])
    setIsOpenModalDetail(false)
  }

  useEffect(() => {
    if (debouncedFilters !== null) {
      getMissions()
    }
  }, [debouncedFilters]) //eslint-disable-line

  return (
    <>
      {filters && (
        <Filter
          filters={filters}
          clearFilters={clearFilters}
          isLoading={isLoadingMissions}
          onChangeFilters={onChangeFilters}
          openEditModal={handleEditModal}
          openListEditionMissionBatch={() =>
            setOpenListEditionMissionBatch(true)
          }
          onOpenReplaceModal={() =>
            setOpenReplaceModal(prevState => !prevState)
          }
        />
      )}

      <TableComponent
        showEllipsis
        count={count}
        data={missions}
        siblingRange={1}
        boundaryRange={0}
        columns={columns}
        showFirstAndLastNav
        emptyText={emptyText}
        showPreviousAndNextNav
        limit={filters?.limit}
        totalCount={totalCount}
        activePage={activePage}
        offset={filters?.offset}
        isLoading={isLoadingMissions}
        handleColumnSort={handleSort}
        handlePaginationChange={onPageChange}
        renderItem={(item, index) => (
          <Item
            item={item}
            key={item.uid}
            openModalForms={() => openModalForms(item)}
            openModalDetail={() => openModalDetail(item)}
            removeItem={() => showConfirmModal(item, index)}
            openModalAttachments={() => openModalAttachment(item)}
            updateStatus={() => updateMissionRowStatus(item, index)}
            openModalParticipants={() => openModalParticipants(item)}
          />
        )}
      />

      {selectedMission && isOpenModalForm && (
        <Forms
          mission={selectedMission}
          clientId={selectedMission.customer_id}
          isOpenModalForm={isOpenModalForm}
          onCloseModalForm={() => setIsOpenModalForm(false)}
        />
      )}

      <CurrentParticipants
        data={currentParticipants}
        open={isOpenModalParticipants}
        onClose={() => setIsOpenModalParticipants(false)}
      />

      {selectedMission && isOpenModalAttachments && (
        <Attachments
          selectedMissionUid={selectedMission.uid}
          customerId={selectedMission.customer_id}
          isOpenModalAttachments={isOpenModalAttachments}
          onCloseModalAttachments={() => setIsOpenModalAttachments(false)}
        />
      )}

      {selectedMission && isOpenModalDetail && (
        <Detail
          getSubCategoryById={getSubCategoryById}
          onChangeInputValueCustomField={onChangeInputValueCustomField}
          customFields={customFields}
          customFieldsAnswers={customFieldsAnswersOfMissionSelected}
          address={addresses}
          handlerDate={handlerDate}
          editorState={editorState}
          handlerInput={handlerInput}
          errors={errorsMissionDetail}
          changeAddress={changeAddress}
          selectedMission={selectedMission}
          loadingAddresses={loadingAddresses}
          isOpenModalDetail={isOpenModalDetail}
          handlerCategories={onChangeCategory}
          close={() => closeModalDetail()}
          onEditorStateChange={onEditorStateChange}
          submitMissionDetail={submitMissionDetail}
        />
      )}

      <ListEditionMissionBatch
        open={openListEditionMissionBatch}
        close={() => setOpenListEditionMissionBatch(false)}
      />

      <ModalEditAllMissions
        forms={forms}
        customerId={filters?.customerId}
        handlerForms={handlerForms}
        address={addresses}
        isActive={isActive}
        value={selectedMission}
        handlerDate={handlerDate}
        editorState={editorState}
        handlerInput={handlerInput}
        errors={errorsMissionDetail}
        changeAddress={changeAddress}
        loadingMissions={loadingMissions}
        isOpenEditModal={isOpenEditModal}
        selectedMission={selectedMission}
        loadingAddresses={loadingAddresses}
        handlerChangeStatus={onChangeStatus}
        handlerCategories={onChangeCategory}
        close={() => setIsOpenEditModal(false)}
        onEditorStateChange={onEditorStateChange}
        onChangeActive={() => setIsActive(prevState => !prevState)}
        submitMissionEdit={() => setShowConfirmEditAllMissions(true)}
      />

      {openReplaceModal && (
        <Replace
          filters={filters}
          open={openReplaceModal}
          onClose={() => setOpenReplaceModal(prevState => !prevState)}
        />
      )}

      <Confirm
        header="Alerta"
        open={showConfirm}
        onConfirm={onRemove}
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onCancel={() => setShowConfirm(false)}
        content="Tem certeza deseja remover este item?"
      />

      <Confirm
        header="Alerta"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        open={showConfirmEditAllMissions}
        onConfirm={() => submitMissionEdit(isActive)}
        content={<ConfirmModal totalCount={totalCount} />}
        onCancel={() => setShowConfirmEditAllMissions(false)}
      />
    </>
  )
}
