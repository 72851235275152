import React from 'react'

import { format } from 'date-fns'
import t from 'prop-types'
import { Card, Feed, Icon } from 'semantic-ui-react'

import { iconColor, feedIcon, feedLabel } from '~/utils/feedsActions'

const Feeds = ({ onClick, items }) => (
  <Card fluid style={{ height: 505, maxHeight: 600, overflow: 'auto' }}>
    <Card.Content>
      <Card.Header>{items.length} Feeds</Card.Header>
      <Feed>
        {items.length > 0 &&
          items?.map((item, index) => {
            const { lat, lng, action } = item
            return (
              <Feed.Event key={String(index)}>
                <Feed.Label>
                  <Icon
                    circular
                    inverted
                    style={{ marginRight: 10 }}
                    color={iconColor(action)}
                    name={feedIcon(action)}
                  />
                </Feed.Label>
                <Feed.Content>
                  <Feed.Summary>
                    <Feed.User onClick={() => onClick({ lat, lng })}>
                      {feedLabel(action)}
                    </Feed.User>
                    <Feed.Date
                      content={format(
                        new Date(item.created_at),
                        'dd/MM/yyyy HH:mm'
                      )}
                    />
                  </Feed.Summary>
                  <Feed.Meta>
                    ({lat}, {lng})
                  </Feed.Meta>
                </Feed.Content>
              </Feed.Event>
            )
          })}
      </Feed>
    </Card.Content>
  </Card>
)

Feeds.propTypes = {
  onClick: t.func,
  items: t.arrayOf(t.any),
}

export default Feeds
